import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout'

class BlogPost extends Component {
    render() {
        const {
            title,
            content,
            subtitle
        } = this.props.data.contentfulBlog
        return (
            <Layout>

            <body>
            <div className="artikelInhalte">
                <h1>{title}</h1>
                <h3>{subtitle}</h3>
                <div dangerouslySetInnerHTML={{__html: content.childContentfulRichText.html}}/>
            </div>
            <div id="bg2"></div>
            </body>

            </Layout>
            
        )
    }
}

BlogPost.propTypes = {
    data: PropTypes.object.isRequired
}

export default BlogPost

export const pageQuery = graphql`
    query blogPostQuery($slug: String!){
        contentfulBlog(slug: {eq: $slug}) {
            title
            slug
            subtitle
            content{
                childContentfulRichText {
                    html
                }
            }
        }
    }
`